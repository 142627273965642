<template>
  <div class="page_container">
    <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="news_content container">
            <!-- <div class="content_title">
                <h4>响应国家战略，紧抓发展机遇 秉匠科技在南通建⽴研发基地</h4>
            </div>
            <div class="content_time">
                发布时间：2020-4-21 17:20
            </div>
            <div class="content_report">
                <div class="content_report_text">
                    2020年4⽉21⽇上午，南通⾼新技术产业开发区科技新城管委会与南通秉匠信息科技有限公司在江海智汇园举⾏签约仪式。科技新城管委会主任姜⼩国与秉匠信息科技总经理夏海兵共同出席了此次签约仪式。
                </div>

                <div class="content_report_text">
                    <p>
                        秉匠科技是业内领先的超⾼性能图形引擎及⼯程数字建造平台研发的⾼新技术企业，基于⾃主研发的“⿊洞”引擎，集成应⽤分布式计算、数据挖掘、云计算等信息化技术，成功助⼒⼤型桥梁、市政道路、轨道交通、⺠⽤建筑等各类型项⽬的精细化管理，并与⻓安⼤学、上海路桥集团、Bentley等⾏业领跑者建⽴了战略合作关系，基于产、学、研、⽤的合作模式打造⾏业解决⽅案。
                    </p>
                    <p>公司深度贯彻《上海市推进智慧城市建设“⼗三五”规划》的核⼼思想，其核⼼技术已获得多项专利和⼏⼗项软件著作权。性能卓越的⾃主引擎，是打造“数字孪⽣”的智慧城市⽣态系统的强⼒⽀撑。</p>
                </div>
                <div class="content_report_picture">
                    <img src="../../assets/image/news/new_nt.jpg" alt="">
                </div>
                <div class="content_report_text">
                    <h1>区位布局</h1>
                    <p>
                        为顺应“⻓三⻆”发展战略，紧抓“新基建”发展机遇，秉匠科技投资成⽴了南通秉匠信息科技有限公司(以下简称南通秉匠)。
                    </p>
                    <p>
                        南通秉匠将基于⺟公司积累的核⼼技术优势、项⽬经验及特有的交通基础设施渠道资源，组建南通本地化的研发团队和销售体系，夯实技术优势，完善产品架构。
                    </p>
                    <p>作为”中国近代第⼀城”,”建筑之乡”,南通完成了从” 建筑铁军” 到” 智慧城市”的嬗变,是全国唯⼀获得“中欧绿⾊智慧城市、国家智慧城市”两个国家级试点的地级市，国家《关于促进智慧城市健康发展的指导意⻅》的践⾏者。</p>
                    <p>此次深度布局以南通为基点，向西推进⾄江苏各市及安徽市场，最终打造”⻓三⻆”智慧城市⽣态系统。</p>
                    <h1>深度探讨</h1>
                    <p>秉匠科技和科技新城管委会正式达成战略合作，在会上双⽅对项⽬的规划、研发及运营等各⽅⾯进⾏了商谈，着⼒打造上海秉匠信息科技在南通的重要研发基地，带动公司的规模化发展。</p>
                    <p>此次签约是双⽅合作的良好开端，以期在共同努⼒之下，充分发挥各⾃的优势资源，精诚合作，互利共赢，为园区转型升级提供强⼤动⼒。</p>
                    <p>秉匠信息科技总经理夏海兵表⽰，从公司全局战略综合考虑，在南通⾼新区成⽴研发中⼼是⼀个良好的开端，落地完成后将会打破现有的产能局限和服务壁垒。</p>
                    <p>双⽅也集中讨论交流了BIM技术在建筑业的应⽤情况，并表⽰将对推动⾏业建造过程向以“精益建造”为核⼼、“智慧建造”为关键、“绿⾊建造”为引领的⽅向作出全⾯探索。</p>
                </div>
                <div class="content_report_picture">
                    <img src="../../assets/image/news/new_nt2.jpg" alt="">
                </div>
                <div class="content_report_text">
                    <p>秉匠科技助⼒园区，除了对驱动产业转型升级，增加财政收⼊，促进当地就业有贡献外，还将着⼒推动⾏业创新转型与升级，同时带动产业上下游企业和周边区域快速崛起，努⼒成为推动⾏业发展的强⼒引擎，创建“数字孪⽣”的智慧城市产业⽣态链。</p>
                </div>
            </div> -->
      <div class="left">
        <div class="tit">
          <h1>响应国家战略，紧抓发展机遇 秉匠科技在南通建⽴研发基地</h1>
          <div class="time">发布时间：2020-4-21 17:20</div>
          <div class="info">
            <p>2020年4⽉21⽇上午，南通⾼新技术产业开发区科技新城管委会与南通秉匠信息科技有限公司在江海智汇园举⾏签约仪式。科技新城管委会主任姜⼩国与秉匠信息科技总经理夏海兵共同出席了此次签约仪式。+GIS 三维图形引擎采购合同。</p>
            <p> 秉匠科技是业内领先的超⾼性能图形引擎及⼯程数字建造平台研发的⾼新技术企业，基于⾃主研发的“⿊洞”引擎，集成应⽤分布式计算、数据挖掘、云计算等信息化技术，成功助⼒⼤型桥梁、市政道路、轨道交通、⺠⽤建筑等各类型项⽬的精细化管理，并与⻓安⼤学、上海路桥集团、Bentley等⾏业领跑者建⽴了战略合作关系，基于产、学、研、⽤的合作模式打造⾏业解决⽅案。</p>
            <p>公司深度贯彻《上海市推进智慧城市建设“⼗三五”规划》的核⼼思想，其核⼼技术已获得多项专利和⼏⼗项软件著作权。性能卓越的⾃主引擎，是打造“数字孪⽣”的智慧城市⽣态系统的强⼒⽀撑。</p>
            <p class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/new_nt.jpg"></span>
            </p>
            <h1 style="text-align:left;">区位布局</h1>
            <p>为顺应“⻓三⻆”发展战略，紧抓“新基建”发展机遇，秉匠科技投资成⽴了南通秉匠信息科技有限公司(以下简称南通秉匠)。</p>
            <p>南通秉匠将基于⺟公司积累的核⼼技术优势、项⽬经验及特有的交通基础设施渠道资源，组建南通本地化的研发团队和销售体系，夯实技术优势，完善产品架构。</p>
            <p>作为”中国近代第⼀城”,”建筑之乡”,南通完成了从” 建筑铁军” 到” 智慧城市”的嬗变,是全国唯⼀获得“中欧绿⾊智慧城市、国家智慧城市”两个国家级试点的地级市，国家《关于促进智慧城市健康发展的指导意⻅》的践⾏者。</p>
            <p>作为”中国近代第⼀城”,”建筑之乡”,南通完成了从” 建筑铁军” 到” 智慧城市”的嬗变,是全国唯⼀获得“中欧绿⾊智慧城市、国家智慧城市”两个国家级试点的地级市，国家《关于促进智慧城市健康发展的指导意⻅》的践⾏者。</p>
            <p>此次深度布局以南通为基点，向西推进⾄江苏各市及安徽市场，最终打造”⻓三⻆”智慧城市⽣态系统。</p>
            <h1 style="text-align:left;">深度探讨</h1>
            <p>秉匠科技和科技新城管委会正式达成战略合作，在会上双⽅对项⽬的规划、研发及运营等各⽅⾯进⾏了商谈，着⼒打造上海秉匠信息科技在南通的重要研发基地，带动公司的规模化发展。</p>
            <p>此次签约是双⽅合作的良好开端，以期在共同努⼒之下，充分发挥各⾃的优势资源，精诚合作，互利共赢，为园区转型升级提供强⼤动⼒。</p>
            <p>秉匠信息科技总经理夏海兵表⽰，从公司全局战略综合考虑，在南通⾼新区成⽴研发中⼼是⼀个良好的开端，落地完成后将会打破现有的产能局限和服务壁垒。</p>
            <p>双⽅也集中讨论交流了BIM技术在建筑业的应⽤情况，并表⽰将对推动⾏业建造过程向以“精益建造”为核⼼、“智慧建造”为关键、“绿⾊建造”为引领的⽅向作出全⾯探索。</p>
            <p class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/new_nt2.jpg"></span>
            </p>
            <p>秉匠科技助⼒园区，除了对驱动产业转型升级，增加财政收⼊，促进当地就业有贡献外，还将着⼒推动⾏业创新转型与升级，同时带动产业上下游企业和周边区域快速崛起，努⼒成为推动⾏业发展的强⼒引擎，创建“数字孪⽣”的智慧城市产业⽣态链。</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tit"><h3><font></font>热点内容</h3></div>
        <ul>
          <li v-for="item in hotList" :key="item.id">
            <p><router-link :to="item.link">{{item.name}}</router-link></p>
            <span>{{item.time}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hotList: [
        {id:1,name: '“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台',time: '2020-11-09',link:'/newsDetails_new_CIM'},
        {id:2,name: '秉匠科技又双叒叕获奖了！',time: '2020-10-28',link:'/newsDetails_new_BIM'},
        {id:3,name: '江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同',time: '2020-11-02',link:'/newsDetails_new_dongjiao'},
        {id:4,name: '“黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖',time: '2020-8-10',link:'/newsDetails_new_hj'}
      ]
    }
  },
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.page_container{
  margin-top: 50px;
  .news_content{
    overflow: hidden;
    .left{
      width: 60%;
      height: 100%;
      .tit{
        h1{
          color: #222;
          font-size: 22px;
          font-weight: normal;
          line-height: 30px;
        }
        .time{
          margin: 10px;
          height: 27px;
          border-bottom: 1px #ddd solid;
          font-size: 14px;
          color: #999;
          padding-right: 30px;
        }
      }
      .info{
        padding-top: 32px;
        text-align: center;
        p{
          font-size: 16px;
          color: #222;
          line-height: 28px;
          text-align: left;
          text-indent:2em;
        }
        .p_pic{
          text-indent: 0;
        }
        img{
          margin: 16px 0;
        }
      }
    }
    .right{
      width: 35%;
      height: 100%;
      .tit{
        border-bottom: 1px #444 solid;
        padding-bottom: 14px;
        margin-top: 24px;
        font{
          width: 4px;
          height: 18px;
          background: #222;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: 4px;
        }
      }
      ul{
        padding: 12px;
        li{
          line-height: 24px;
          font-size: 16px;
          color: #555;
          border-bottom: 1px #ddd solid;
          padding: 12px 0;
          p{
            a{
              color: #555;
            }
          }
          span{
            display: block;
            font-size: 14px;
            color: #777;
            padding-top: 4px;
          }
        }
        .router-link-active{
          border-bottom: none;
        }
      }
    }
  }
}
</style>
